import React from 'react'
import { Layout, Stack, Main } from '@layout'
import PageTitle from '@components/PageTitle'
import Divider from '@components/Divider'
import Seo from '@widgets/Seo'

const PageAbout = props => {

  return (
    <Layout {...props}>
      <Seo title='Terms of User' />
      <Divider />
      <Stack effectProps={{ effect: 'fadeInDown' }}>
        <PageTitle
          header='Terms of Use'
          subheader='Please read the following'
        />
      </Stack>
      <Stack>
        <Main>
        
        <h1>About Us</h1>

        <p>Magnesium Truth is a news site about Magnesium Research. When they work,&nbsp;Magnesium make us stronger, faster, cleverer and younger.</p>
        
        <p>Our aim is not to encourage you to use&nbsp;Magnesium. Nor is it our aim to discourage you if you do use them. We do not approve or disapprove.&nbsp;Magnesium is &lsquo;good&rsquo; as far as we are concerned. We are fascinated by them. We report on them. We bring you News.</p>
        
        <p>We are a News site. We do not proclaim that we have a cure or relief. We do not tell you what you should and should not do. If we report on the harmful effects of magnesium, we do not say that you &lsquo;therefore&rsquo; shouldn&rsquo;t take magnesium. If we report on a study that says the risks are minimal, we do not say that the risks are &lsquo;therefore&rsquo; safe.</p>
        
        <p>Magnesium Truth is&nbsp;<em>not</em>&nbsp;about proclaiming cure or relief and does&nbsp;<em>not</em>&nbsp;have a Message. There are enough people in the world who have already proclaiming to be true. Most of them are highly dangerous. Magnesium Truth does not believe in proclaiming cure. We believe in intelligent people who are open to learning about new insights, and who can work out for themselves what is of value and leave the rest for what it is.</p>
        
      
        </Main>
      </Stack>
    </Layout>
  )
}

export default PageAbout
